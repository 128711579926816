<template>
  <nav class="nav" :class="{ 'nav--open': isMenuOpen }">
    <div class="nav__header">
      <a href="#" @click="$router.push({ name: 'Home' })"
        ><img class="nav__logo" :src="logoSrc" alt="logo"
      /></a>
      <button class="nav__toggle" @click="toggleMenu">
        <span class="nav__menu" v-if="!isMenuOpen">☰</span>
        <img v-else src="@/assets/close.svg" alt="Close" />
      </button>
    </div>
    <ul class="nav__list">
      <li class="nav__item">
        <router-link :to="'/#'" :class="routeClass" @click="closeModals"
          >Home</router-link
        >
      </li>
      <li class="nav__item">
        <router-link :to="'/research'" :class="routeClass" @click="closeModals"
          >Research</router-link
        >
      </li>
      <!-- <li class="nav__item">
        <router-link :to="'/about'" class="nav__link" @click="closeModals"
          >About Us</router-link
        >
      </li> -->
    </ul>
    <div class="nav__buttons">
      <button :class="buttonClass" @click="$emit('open-demo-modal')">
        Request demo
      </button>
      <button
        v-if="!this.token"
        class="nav__button"
        @click="$emit('open-login-modal')"
      >
        Login
      </button>
      <button
        v-if="this.token"
        class="nav__button"
        @click.stop="goToBackoffice"
      >
        Platform
      </button>
    </div>
  </nav>
</template>

<script>
import axios from "axios";
import logoLight from "@/assets/nav/logo-light.svg";
import logo from "@/assets/logo.svg";
export default {
  data() {
    return {
      isMenuOpen: false,
      windowWidth: 0,
      token: "",
    };
  },
  mounted() {
    this.getToken();
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth);
  },

  computed: {
    logoSrc() {
      if (this.$route.name === "Home") {
        return logoLight;
      } else if (
        this.$route.name === "ResearchPage" ||
        this.$route.name === "SetPassword"
      ) {
        return logo;
      } else {
        return "";
      }
    },
    routeClass() {
      if (this.$route.name === "Home") {
        return "nav__link";
      } else if (
        this.$route.name === "ResearchPage" ||
        this.$route.name === "SetPassword"
      ) {
        return "nav__research-link";
      } else {
        return "";
      }
    },
    buttonClass() {
      if (this.$route.name === "Home") {
        return "nav__button";
      } else if (
        this.$route.name === "ResearchPage" ||
        this.$route.name === "SetPassword"
      ) {
        return "nav__research-button";
      } else {
        return "";
      }
    },
  },

  methods: {
    validateToken(token) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        url: "https://api-brands.assetfloow.com/api/v1/auth/login/",
      })
        .then((response) => {
          if (response.status != 200) {
            this.token = "";
            window.$cookies.remove("auth-token", null, ".assetfloow.com");
          } else {
            this.token = response.data.auth_token;
            return response;
          }
        })
        .catch(() => {
          window.$cookies.remove("auth-token");
          this.token = "";
        });
    },
    goToBackoffice() {
      window.location.assign("https://ai.assetfloow.com");
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeModals() {
      this.$emit("close-modals");
      this.isMenuOpen = false;
    },
    updateWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth >= 900) {
        this.isMenuOpen = false;
      }
    },
    getToken() {
      this.token = window.$cookies.get("auth-token");
      if (this.token) {
        this.validateToken(this.token);
      }
    },
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styles.scss";

.nav {
  color: $white-color;
  width: 100%;
  padding: $nav-padding;
  position: absolute;
  top: 0;

  @include responsive(1025px) {
    @include flex-center;
  }

  &--open {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background: url("@/assets/introduction/background-hero.png");
    background-size: cover;
    align-items: flex-start;

    .nav__header {
      position: relative;
      top: 7px;
    }

    .nav__list {
      @include flex-direction(column);

      display: block;
    }

    .nav__buttons {
      @include flex-center;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 80px;
      padding: 0 5%;
    }
  }

  &__header {
    @include flex-center;
    width: 100%;

    @include responsive(1025px) {
      justify-content: flex-start;
      width: auto;
    }
  }

  &__logo {
    width: $logo-width-small;

    @include responsive(1025px) {
      width: $logo-width-large;
    }
  }

  &__toggle {
    border: none;
    background: none;
    cursor: pointer;

    @include responsive(1025px) {
      display: none;
    }
  }

  &__menu {
    font-size: 30px;
    color: white;
  }

  &__list {
    display: none;
    flex-direction: column;
    margin-top: 60px;
    list-style: none;

    @include responsive(1025px) {
      @include flex-direction;

      margin: 0;
      padding: 0;
    }
  }

  &__item {
    margin-right: 1.5rem;

    @include responsive(1025px) {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__link {
    font-size: 32px;
    line-height: 62px;
    color: white;
    text-decoration: none;

    &:hover {
      border-bottom: 2px solid;
    }

    @include responsive(1025px) {
      font-size: initial;
      line-height: initial;
    }
  }

  &__research-link {
    font-size: 32px;
    line-height: 62px;
    color: #00204d;
    text-decoration: none;

    &:hover {
      border-bottom: 2px solid;
    }

    @include responsive(1025px) {
      font-size: initial;
      line-height: initial;
    }
  }

  &__buttons {
    display: none;

    @include responsive(1025px) {
      @include flex-center;
      position: relative;
      width: auto;
    }
  }

  &__research-button {
    @include button;

    box-shadow: none;
    background: transparent;
    color: #00204d;
    border-color: #00204d;

    &:hover {
      border-color: #2768f6;
    }

    &:nth-child(2) {
      @include button;

      background: $button-background-2;
      border: none;
      box-shadow: none;
      color: $white-color;

      @include responsive(1025px) {
        width: auto;
        margin-left: 0.5rem;
      }
    }
  }

  &__button {
    @include button;

    box-shadow: none;
    background: transparent;
    color: white;
    border-color: white;

    &:nth-child(2) {
      @include button;

      background: $button-background-2;
      border: none;
      box-shadow: none;
      color: $white-color;

      @include responsive(1025px) {
        width: auto;
        margin-left: 0.5rem;
      }
    }
  }
}

.router-link-active {
  border-bottom: 2px solid;
}
</style>
