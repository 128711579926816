import { createApp } from 'vue';
import App from './App.vue';
import './styles/styles.scss'
import router from './router';
import AOS from 'aos';
import VueParticles from 'vue-particles'  
import 'aos/dist/aos.css';

import VueCookies from "vue-cookies";
import '@mdi/font/css/materialdesignicons.min.css';

const app = createApp(App);

app.use(router);
app.use(VueCookies);
app.use(VueParticles);

app.mount('#app');

AOS.init();
