<template>
  <div class="demo" id="demo" v-if="isOpen">
    <div class="demo__wrapper">
      <div class="demo__content container">
        <h4 class="demo__headline">Request a Demo</h4>
        <h4 class="demo__text">
          We may use the information only to follow-up by email to schedule a
          demo or a meeting.
        </h4>
        <span class="demo__close-btn" @click="closeModal">&times;</span>
        <form class="demo__form" @submit.prevent="submitForm">
          <label class="demo__label hidden" for="name">Name:</label>
          <input
            class="demo__input"
            type="text"
            v-model="name"
            placeholder="Name"
          />

          <label class="demo__label hidden" for="email_request">Email:</label>
          <input
            class="demo__input"
            type="email"
            v-model="email"
            placeholder="Email"
          />

          <label class="demo__label hidden" for="company">Company:</label>
          <input
            class="demo__input"
            type="text"
            v-model="company"
            placeholder="Company"
          />
          <textarea
            id="message"
            class="demo__textarea"
            placeholder="Message"
            v-model="message"
          ></textarea>
          <vue-recaptcha
            style="margin-top: -50px; margin-left: 5px"
            ref="recaptcha"
            @verify="onVerify"
            :sitekey="siteKey"
          ></vue-recaptcha>
          <br />

          <button class="demo__button" id="submit_demo_btn" type="submit">
            Request a demo
          </button>
        </form>
      </div>
      <div id="snackbar-demo"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    VueRecaptcha,
  },

  data() {
    return {
      siteKey: "6LdDiM0kAAAAAJPitYRdhkSyf_3VxqTIUNdF4MWr",
      name: null,
      email: null,
      company: null,
      message: null,
      response: null,
    };
  },
  methods: {
    onVerify(response) {
      // Handle the verified response
      this.response = response;
    },
    closeModal() {
      this.name = "";
      this.email = "";
      this.company = "";
      this.response = "";
      this.message = "";
      this.$emit("close");
    },
    clearFields() {
      this.name = "";
      this.email = "";
      this.company = "";
      this.message = "";
      this.response = "";
    },
    submitForm() {
      if (!this.name || !this.email || !this.company) {
        this.requiredFields();
        return;
      }
      if (!this.response || this.response.length === 0) {
        this.requiredFields(true);
        return;
      }

      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: "https://api-brands.assetfloow.com/api/v1/demos/",
        data: {
          name: this.name,
          email: this.email,
          company: this.company,
          message: this.message,
          response: this.response,
        },
      })
        .then(function (response) {
          if (response.status != 201) {
            this.showSnackbarError(
              "There was an error sending the request. Try again later."
            );
          } else {
            return response;
          }
        })
        .then(() => {
          this.clearFields();
          this.showSnackbarSuccess("Demo request sent successfully.");
        })
        .catch(() => {
          this.showSnackbarError(
            "There was an error sending the request. Try again later."
          );
        });
    },
    showSnackbarSuccess(text) {
      const snackbar = document.querySelector("#snackbar-demo");
      snackbar.className = "show";
      snackbar.innerHTML = "✔ " + text;
      snackbar.style.background = "#7da53a";
      snackbar.style.left = "72%";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
        snackbar.style.left = "40%";
      }, 3000);
    },
    showSnackbarError(error) {
      const snackbar = document.querySelector("#snackbar-demo");
      snackbar.className = "show";
      snackbar.innerHTML = "✗ " + error;
      snackbar.style.background = "#962929";
      snackbar.style.left = "65.3%";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
        snackbar.style.left = "40%";
      }, 3000);
    },
    requiredFields(isRecaptcha) {
      const snackbar = document.querySelector("#snackbar-demo");
      snackbar.className = "show";
      snackbar.innerHTML = isRecaptcha
        ? "Please check the reCAPTCHA."
        : "！Please insert all the fields.";
      snackbar.style.left = "75%";
      snackbar.style.background = "#e5ab46";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styles.scss";

#snackbar-demo {
  visibility: hidden;
  /* Hidden by default. Visible on click */
  min-width: 250px;
  /* Set a default minimum width */
  background-color: #333;
  /* Black background color */
  color: #fff;
  /* White text color */
  text-align: center;
  /* Centered text */
  border-radius: 8px;
  /* Rounded borders */
  padding: 0.5rem 1.5rem;
  /* Padding */
  position: fixed;
  /* Sit on top of the screen */
  z-index: 1;
  /* Add a z-index if needed */
  left: 40%;
  /* Center the snackbar */
  bottom: 30px;
  /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar-demo.show {
  visibility: visible;
  /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

.demo {
  position: fixed;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  z-index: 24;
  top: 0;
  left: 0;

  &__textarea {
    width: 100%;
    border: none;
    transition: border-bottom-color 0.3s ease;
    resize: none;
    margin-bottom: 80px;
    padding: 1rem;
    border-radius: 1rem;
    border: none;
  }

  &__wrapper {
    position: fixed;
    z-index: 9;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(246, 249, 252);
    background: linear-gradient(
      180deg,
      rgba(246, 249, 252, 1) 0%,
      rgba(251, 242, 232, 1) 100%
    );

    @include responsive($breakpoint) {
      right: 0;
      width: 480px;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
    }
  }

  &__content {
    margin: 10% auto;
    padding-top: 20px;
    width: 100%;

    @include responsive($breakpoint) {
      max-width: 400px;
    }
  }

  &__headline {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 0.5rem;
  }

  &__text {
    color: #abb3bf;
    font-size: 18px;
    margin-bottom: 20px;
  }

  &__close-btn,
  .demo-login__close-btn {
    font-size: 50px;
    position: absolute;
    right: 1rem;
    top: 0.5rem;

    &:hover,
    &:focus {
      color: lighten(black, 45%);
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__input[type="text"],
  &__input[type="email"],
  &__input[type="password"] {
    width: 100%;
    padding: 1rem;
    border-radius: 2rem;
    border: none;
    margin-bottom: 1rem;
  }

  &__button {
    @include button(fit-content, fit-content);
  }
}

::placeholder {
  font-family: "aeoniklight";
  color: #abb3bf;
  font-size: 16px;
}

.demo__textarea:focus {
  outline: none;
  border-bottom-color: inherit;
}
</style>
